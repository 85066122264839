import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { Col, Container, Row, } from 'react-bootstrap'

export default function() {
    const data = useStaticQuery(query)
    const {allSanityAldAstraBanner:{
        nodes:aldAstraBanner
    }} = data
    return (
        <div className={`${aldAstraBanner[0].bannercolor} single-service-banner`}>  
            <Container> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="banner">
                        <h1 className="name">{aldAstraBanner[0].title}</h1>
                        <p className="paragraph">{aldAstraBanner[0].paragraph}</p>
                        <a href={aldAstraBanner[0].btnLink} target="_blank"
                            className="primary-btn-s contact-us-btn" 
                        >{aldAstraBanner[0].btnText}</a>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="banner-img"><img src={aldAstraBanner[0].image.asset.url} alt={`${aldAstraBanner[0].title} banner`} /></Col>
                </Row> 
            </Container>  
        </div>
    )
}

export const query = graphql`
{
    allSanityAldAstraBanner {
      nodes {
        bannercolor
        title
        paragraph
        btnText
        btnLink
        image {
          asset {
            url
          }
        }
      }
    }
  }
`