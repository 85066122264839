import React from "react"
import '../assets/style.css';
import {Container} from 'react-bootstrap';

export default function() {
    return (
        <Container> 
            <div className="case-study-aldastra services-case-study services-case-study-container">  
                <div class="services-case-study cx-design">
                <div class="services-case-study-text">
                    <p class="case-study-category">
                       CASE STUDY
                    </p>
                    <h2>Building tomorrow’s world today: Sogody’s R&D chronicles</h2>
                    <a href={`/updates/sogodys-r-and-d-chronicles/`} class="btn btn-primary">Learn more
                         <img src="https://cdn.sanity.io/images/3hfxs7a8/production/1a602a0190ab6e14b416a1b3d18e45c4545e713d-70x50.png" alt="arrow right"/>
                    </a>
                </div>
                <div class="services-case-study-image">
                    <img src='https://cdn.sanity.io/files/3hfxs7a8/production/6563eb208d9ce43fa7cc9d579c30f11bf6847f1f.png' alt="Case Study"/>
                </div>
            </div>
            </div>
        </Container>
    )
}
