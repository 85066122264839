import React from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import {graphql, useStaticQuery } from 'gatsby';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


export default function() {
    return (
        <div className="aldastra-products-tabs">  
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <div className="products-section-head">
                            <h2 className="products-section-title">Ald Astra offers products and technical innovations that are unique, inspired by our extensive expertise and reliable operations. </h2>
                        </div>
                    </Col>
                </Row>
                <Tabs>
                    <div className="products-section-tabs">
                        <Row>
                            <Col xs={12} md={12} lg={12} className="tabs-list-ctn">
                                <TabList>
                                    <Tab>
                                        <div className='tabs-list-image'>
                                            <img src={require('../assets/images/tweetpeek.png').default} className='tweetpeek'/>
                                            <span>TweetPeek</span>
                                        </div>
                                    </Tab>
                                    <Tab>
                                        <div className='tabs-list-image'>
                                            <img src={require('../assets/images/aldachat.png').default}/>
                                            <span>AldaChat</span>
                                        </div>
                                    </Tab>
                                    <Tab>
                                        <div className='tabs-list-image'>
                                            <img src={require('../assets/images/replix.png').default}/>
                                            <span>Replix</span>
                                        </div>
                                    </Tab>
                                    <Tab>
                                        <div className='tabs-list-image'>
                                            <img src={require('../assets/images/testful.png').default}/>
                                            <span>Testful</span>
                                        </div>
                                    </Tab>
                                    <Tab>
                                        <div className='tabs-list-image'>
                                            <img src={require('../assets/images/scopexjs.png').default}/>
                                            <span>ScopexJS</span>
                                        </div>
                                    </Tab>
                                </TabList>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <div className='tab-content'>
                                <TabPanel>
                                   <div className='tab-content-img'>
                                        <img src={require('../assets/images/tweetpeek-illustration.png').default}/>
                                   </div>
                                   <div className='tab-content-info'>
                                       <Row>
                                            <Col xs={12} md={2} lg={1} className='tab-ctn-icon'>
                                                <img src={require('../assets/images/tweetpeek-big.png').default}/>
                                            </Col>
                                            <Col xs={12} md={10} lg={9}>
                                               <h3 className='tab-content-title'>TweetPeek</h3>
                                               <p className='tab-content-description'>Set your follow strategy once and forget about it. Let TweetPeek do its magic and grow your audience, while you do you.</p>
                                            </Col>
                                            <Col xs={12} md={12} lg={2} className='content-info-cta'>
                                                <a href="https://tweetpeek.ai/" target="_blank">Explore</a>
                                            </Col>
                                       </Row>
                                   </div>
                                </TabPanel>
                                <TabPanel>
                                   <div className='tab-content-img'>
                                        <img src={require('../assets/images/aldachat-illustration.png').default}/>
                                   </div>
                                   <div className='tab-content-info'>
                                       <Row>
                                            <Col xs={12} md={2} lg={1} className='tab-ctn-icon'>
                                                <img src={require('../assets/images/aldachat.png').default} width="80"/>
                                            </Col>
                                            <Col xs={12} md={10} lg={9}>
                                               <h3 className='tab-content-title'>AldaChat</h3>
                                               <p className='tab-content-description'>Modern Chat that uses AI to speak & sell for your business. AldaChat is a complete conversation solution powered by advanced AI, designed to transform how your business connects with customers.</p>
                                            </Col>
                                            <Col xs={12} md={12} lg={2} className='content-info-cta'>
                                                <a href="https://chat.aldastra.com" target="_blank">Explore</a>
                                            </Col>
                                       </Row>
                                   </div>
                                </TabPanel>
                                <TabPanel>
                                   <div className='tab-content-img'>
                                        <img src={require('../assets/images/replix-illustration.png').default}/>
                                   </div>
                                   <div className='tab-content-info'>
                                       <Row>
                                            <Col xs={12} md={2} lg={1} className='tab-ctn-icon'>
                                                <img src={require('../assets/images/replix-big-icon.png').default} width='90' height='90'/>
                                            </Col>
                                            <Col xs={12} md={10} lg={9}>
                                               <h3 className='tab-content-title'>ReplixAI</h3>
                                               <p className='tab-content-description'>A comprehensive tool that helps individuals and businesses improve their online presence and engagement. Its advanced features can help elevate professional and personal communications on Gmail, Linkedin, Twitter, Trello, Jira, and more.</p>
                                            </Col>
                                            <Col xs={12} md={12} lg={2} className='content-info-cta'>
                                                <a href="https://www.replix.ai/" target="_blank">Explore</a>
                                            </Col>
                                       </Row>
                                   </div>
                                </TabPanel>
                                <TabPanel>
                                   <div className='tab-content-img'>
                                        <img src={require('../assets/images/testful-illustration.png').default}/>
                                   </div>
                                   <div className='tab-content-info'>
                                       <Row>
                                            <Col xs={12} md={2} lg={1} className='tab-ctn-icon'>
                                                <img src={require('../assets/images/testful.png').default}/>
                                            </Col>
                                            <Col xs={12} md={10} lg={9}>
                                               <h3 className='tab-content-title'>Testful</h3>
                                               <p className='tab-content-description'>Testful automates the delivery of multiple experiments in one go, through A/B templates, which require zero development effort to be updated and deployed across any market.</p>
                                            </Col>
                                            <Col xs={12} md={12} lg={2} className='content-info-cta'>
                                                <a href="https://testful.co.uk/" target="_blank">Explore</a>
                                            </Col>
                                       </Row>
                                   </div>
                                </TabPanel>
                                <TabPanel>
                                   <div className='tab-content-img'>
                                        <img src={require('../assets/images/scopexJS-illustration.png').default}/>
                                   </div>
                                   <div className='tab-content-info'>
                                       <Row>
                                            <Col xs={12} md={2} lg={1} className='tab-ctn-icon'>
                                                <img src={require('../assets/images/scopexjs.png').default} width="80"/>
                                            </Col>
                                            <Col xs={12} md={10} lg={9}>
                                               <h3 className='tab-content-title'>ScopexJS</h3>
                                               <p className='tab-content-description'>Scoped Experiences, a versatile framework for building and deploying standalone web experiences.</p>
                                            </Col>
                                            <Col xs={12} md={12} lg={2} className='content-info-cta'>
                                                <a href="https://www.npmjs.com/package/@sogody/scopexjs" target="_blank">Explore</a>
                                            </Col>
                                       </Row>
                                   </div>
                                </TabPanel>
                                </div>
                            </Col>
                        </Row> 
                    </div>
                </Tabs>
            </Container>
        </div>
    )
}
