import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { Container } from 'react-bootstrap'

export default function() {
    const data = useStaticQuery(query)
    const {allSanityAldAstraDescription:{
        nodes:aldAstraDescription
    }} = data
    return (
      <div className="aldastra-description">
        <Container>
            <div className="description-title">
              {aldAstraDescription[0].title}
            </div>
            <p className="description-text">
              {aldAstraDescription[0].description}  
            </p>
            <a href={aldAstraDescription[0].btnLink} target="_blank" className="primary-btn-s explore-btn btn btn-primary">{aldAstraDescription[0].btnText}</a>
        </Container>
      </div>
    )
}

export const query = graphql`
{
    allSanityAldAstraDescription {
      nodes {
        title
        description
        btnText
        btnLink
      }
    }
  }
`