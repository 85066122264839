import React from "react"
import Layout from '../../components/Layout'
import { Seo } from "../../components/Seo";
import metaImageOg from '../../assets/images/og-image.jpg';
import '../../assets/scss/main.scss';
import { useLocation } from "@reach/router"
import AldAstraBanner from "../../components/AldAstraBanner";
import AldAstraDescription from "../../components/AldAstraDescription";
import AldAstraServices from "../../components/AldAstraServices";
import AldAstraSetMeeting from "../../components/AldAstraSetMeeting";
import CaseStudyAldAstra from "../../components/CaseStudyAldAstra";
import AldAstraProcuctTabs from "../../components/AldAstraProcuctTabs";

export default ()=> {
  const pathLocation = useLocation();
  const url = pathLocation.href;

  return (
    <Layout>
      <Seo 
        title="AldAstra | Sogody" 
        image={'https://sogody.com'+metaImageOg} 
        description="Putting our mind and effort into creating something that differentiates a great platform from a good one. Our team is responsible for the overall web experience for our client's customers."
        url={url}
      />
      <div className="aldastra-page">
          <AldAstraBanner />
          <AldAstraDescription />   
          <AldAstraServices />
          <AldAstraProcuctTabs />
          <AldAstraSetMeeting />
          <CaseStudyAldAstra/>
      </div>
    </Layout>
    )
  }