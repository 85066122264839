import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import wwoBanner from '../assets/images/apps-and-integrations.png';
import edgeTechImg from '../assets/images/edge-technology.png';
import nlpImg from '../assets/images/nlp.png';
import webIntegrationImg from '../assets/images/web-integration.png';
import semanticSearchImg from '../assets/images/semantic-search.png';
export default function () {
  return (
    <div className="aldastra-wwo">  
        <Container> 
            <div className="wwo-po_section-header">
                <div className="wwo-po_head">
                    <h2 className="wwo-po_title">Powerful GPT-4 Apps and Integrations</h2> 
                    <span className="wwo-po_desc">Experience a new era of linguistic possibilities with GPT-4 and witness the transformative impact it can have on your projects and ventures.</span>
                </div>
                <div className="wwo-po_illustration">
                    <img className="illustration" src={wwoBanner} alt="Illustration" />
                </div>
                <div className="wwo-po_illustration mobile">
                    <img className="illustration" src={wwoBanner} alt="Illustration" />
                </div>
            </div>
        </Container>
        <Container> 
            <div className="wwo-po_content">
               <Row>
                    <Col className="wwo-po_col" xs={12} md={6} lg={6}>
                        <div class="solution">
                            <div class="solution-image">
                                <img src={edgeTechImg} alt="Solution"/>
                            </div>
                            <div class="solution-content">
                                <h2 class="solution-title"> Research-backed Cutting Edge Technology. </h2>
                                <p>We go beyond the cutting edge of what's possible with AI. While others follow, we pioneer. Our secret? A bold vision and rapid execution.</p>
                            </div>
                        </div>
                        <div class="solution">
                            <div class="solution-image">
                                <img src={webIntegrationImg} alt="Solution"/>
                            </div>
                            <div class="solution-content">
                                <h2 class="solution-title"> Web Integration of LLMs and Search Systems</h2>
                                <p>Proficiency in integrating large language models and semantic search systems into web-based applications using various APIs.</p>
                            </div>
                        </div>
                    </Col>
                    <Col className="wwo-po_col" xs={12} md={6} lg={6}>
                        <div class="solution">
                            <div class="solution-image">
                                <img src={nlpImg} alt="Solution"/>
                            </div>
                            <div class="solution-content">
                                <h2 class="solution-title"> NLP and Machine Learning</h2>
                                <p> Skilled in training and implementing large language models like GPT-4 using TensorFlow or PyTorch. Capable of handling natural language processing techniques and vector space models for semantic search. </p>
                            </div>
                        </div>
                        <div class="solution">
                            <div class="solution-image">
                                <img src={semanticSearchImg} alt="Solution"/>
                            </div>
                            <div class="solution-content">
                                <h2 class="solution-title"> Semantic Search and Vector Databases </h2>
                                <p>Experienced in building vector databases for semantic search using technologies like Elasticsearch or Faiss, and integrating them into applications.</p>
                            </div>
                        </div>
                    </Col>
               </Row>
            </div>
        </Container>
    </div>
)
}
