import React from 'react'
import {Col, Container, Figure, Row } from 'react-bootstrap'

export default function() {
    return (
        <div className="aldastra-set-meeting">
            <Container> 
                    <div className="set-meeting-container"> 
                        <Row style={{margin: "unset"}}>
                            <Col xs="12" sm="12" md="7" lg="6" className="cta-text">
                                <h1 className="meeting-title">Set up a meeting to get started.</h1>
                                <p className="meeting-subtitle">To know more about us and your project set up a meeting to get started.</p>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="5">
                                        <a href="https://koalendar.com/u/aldastra" target="_blank" className="cta-meeting secondary-btn-s">
                                            Set up a meeting  <Figure className="meeting-img">
                                                <Figure.Image  src={require('../assets/images/arrow-white.svg').default}  />
                                            </Figure>
                                        </a>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="6" className='meet-with-team'>
                                        <p>
                                            <Figure className="meeting-img">
                                                <Figure.Image  src={require('../assets/images/dielleza-synim-profiles.webp').default}  />
                                            </Figure> 
                                            Meet with Diellëza & Synim
                                        </p>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col  xs="12" sm="12" md="5" lg="6" className="cta-img">
                                <Row style={{width:"100%"}}>
                                    <div class="meeting-imgs">
                                        <img src={require('../assets/images/blue-shade-bottom-right.png').default} alt="Logo" className="blue-shade-img"/>
                                        <img src={require('../assets/images/pyramid.png').default} className="pyramid-img"/>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </div>
            </Container>
        </div>
    )
}
